<template>
  <div class='home'>
    <div class='ljq'>Corporate Innovation Journey Is a Marathon, Not a Sprint.</div>
    <p class='gku'>Take our Corporate Innovation Maturity Assessment and get the comprehensive analysis of your corporate innovation journey</p>
    <div class='pgd'>
      <img class='wco'
        src='images/CIMM-Model.png'>
    </div>
    <div class='juq'>
      <router-link :to='{ name: "test" }'>
        <button-element class='fwb'>Take the Assessment</button-element>
      </router-link>
    </div>
    <div class='ljq'>What is Corporate Innovation Maturity Model?</div>
    <p class='gku'>Corporate Innovation Maturity Model™ (CIMM™) is specifically designed for solving the mystery of the top corporate innovation wonders - ‘Where is our organization on the corporate innovation journey?,’ ‘What should be our next moves?,’ ‘How can we ensure that we are on the right track?.’</p>
    <p class='gku'>Developed from the research and experiences with 400+ leading companies worldwide, CIMM™ is a tool to measure the level of corporate innovation maturity for each corporation in order to provide a clear roadmap and individualized recommendations for building, improving, sustaining the corporation’s innovation capability in accordance with its optimum business objectives.</p>
    <p class='gku'>The model categorizes corporations based on the 2 key attributes: People and Technology, and 6 factors: Leadership, Team Capability, Organizational Process, Tools, Data, and Ecosystem.</p>
    <div class='ljq'>How does CIMM help your organization?</div>
    <div class='gbc'>
      <div class='hhf'>
        <img class='vuf'
          src='images/e1f8.png'>
        <div>Help locate the progress of your organization on the corporate innovation journey by assessing its maturity level</div>
      </div>
      <div class='hhf'>
        <img class='vuf'
          src='images/e37b.png'>
        <div>Give you practical recommendations that can guide your organization towards the next stage of maturity</div>
      </div>
      <div class='hhf'>
        <img class='vuf'
          src='images/eb2b.png'>
        <div>Provide you the overview of corporate innovation maturity level within your industry</div>
      </div>
      <div class='hhf'>
        <img class='vuf'
          src='images/ec0d.png'>
        <div>Offer you the complimentary 1-on-1 consultation with our experts after completing the assessment</div>
      </div>
    </div>
    <div class='eyi'>
      <div class='poq'>What Is Innovation Maturity Analysis?</div>
      <router-link :to='{ name: "maturity-analysis" }'>
        <button-element>Learn More</button-element>
      </router-link>
    </div>
    <div class='juq juq-bottom'>
      <router-link :to='{ name: "test" }'>
        <button-element class='fwb'>Take the Assessment</button-element>
      </router-link>
    </div>
    <div class='ljq' style='display:none;'>Testimonials</div>
    <div class='gbc' style='display:none;'>
      <template
        v-for='testimonial in testimonials'
        :key='testimonial.id'>
        <div class='lsd'>
          <img class='coy'
            :src='testimonial.client.companyLogoImageUrl'>
          <div class='grw'
            v-html='testimonial.statement'>
          </div>
          <img class='cta'
            :src='testimonial.client.avatarImageUrl'>
          <div class='cpo'>{{ testimonial.client.position }}, {{ testimonial.client.companyName }}</div>
        </div>
      </template>
    </div>
    <div class='kgt'
      :style='{ backgroundImage: "url(images/04b1.png)" }'>
    </div>
  </div>
</template>

<script>
  import { computed, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { FETCH_TESTIMONIALS } from '@/store/action-types'

  export default {
    setup () {
      let store = useStore()
      let testimonials = computed(() => store.state.testimonials.testimonials)

      onMounted(() => {
        store.dispatch(FETCH_TESTIMONIALS)
      })

      return { testimonials }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .home {
    position: relative;
    z-index: 0;
  }

  .ljq {
    margin: 48px 0 24px;
    font-weight: map.get(variables.$font-weights, 'bold');
    font-size: map.get(variables.$font-sizes, 'xl');
    line-height: 1.5;

    &:first-child {
      margin-top: 24px;
    }
  }

  .gku {
    margin: 24px 0;
    line-height: 1.5;
  }

  .pgd {
    padding: 24px;
    background-color: white;
    border-radius: 4px;
  }

  .wco {
    display: block;
    margin: auto;
    max-width: 100%;
  }

  .gbc {
    display: flex;
    overflow-x: auto;
  }

  .hhf {
    padding: 16px;
    width: 100%;
    min-width: 240px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 4px;
    text-align: center;
    line-height: 1.5;

    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  .vuf {
    margin-bottom: 16px;
    width: 120px;
    height: 120px;
  }

  .eyi {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
    padding: 24px;
    background-color: white;
    border-radius: 4px;
  }

  .poq {
    margin-bottom: 24px;
    font-weight: map.get(variables.$font-weights, 'bold');
    font-size: map.get(variables.$font-sizes, 'lg');
  }

  .lsd {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    width: 100%;
    min-width: 300px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 4px;
    text-align: center;

    &:not(:first-child) {
      margin-left: 16px;
    }
  }

  .coy {
    max-width: 100%;
    max-height: 40px;
  }

  .grw {
    flex-grow: 1;
    margin-top: 24px;
    line-height: 1.5;
  }

  .cta {
    margin-top: 24px;
    width: 56px;
    height: 56px;
    border-radius: 4px;
  }

  .cpo {
    margin-top: 24px;
    min-height: 2rem;
    font-size: map.get(variables.$font-sizes, 'sm');
  }

  .juq {
    display: flex;
    flex-direction: column;
    margin: 48px 0 24px;
  }

  .fwb {
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .kgt {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
  }

  @media (min-width: map.get(variables.$breakpoints, 'xs')) {
    .eyi {
      flex-direction: row;
    }

    .poq {
      margin-bottom: 0;
    }

    .juq {
      flex-direction: row;
      justify-content: center;

      &.juq-bottom {
        margin-bottom: 64px;
      }
    }
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .ljq {
      margin: 64px 0 32px;

      &:first-child {
        margin-top: 32px;
      }
    }

    .gku {
      margin: 32px 0;
    }

    .pgd {
      padding: 32px;
    }

    .hhf {
      min-width: 0;
    }

    .eyi {
      margin-top: 64px;
      margin-bottom: 128px;
      padding: 32px;
    }

    .coy {
      max-height: 56px;
    }

    .grw {
      margin-top: 32px;
    }

    .cta {
      margin-top: 32px;
    }

    .cpo {
      margin-top: 32px;
    }

    .juq {
      margin: 64px 0 32px;
    }
  }
</style>
